<template>

	<div class="user">
		<h4>
            <span>常用开票信息</span>
            <img src="../../assets/signup/add.png" alt="">
            <span class="add" @click="dia=true">添加</span>
        </h4>

        <div class="rbox">
        <div class="box" v-for="(item,i) in list" :key="i">
            <div class="b1">
                <p>
                   开具类型
                </p>
                <p>{{item.issue_type}}</p>
            </div>
            <div class="b1">
                <p>发票内容</p>
                <p>{{item.content}}</p>
            </div>
            <div class="b1">
                <p>发票抬头</p>
                <p>{{item.billing_rise}}</p>
            </div>
            <div class="b2 b1">
                <div>
                    <p>税务登记证号</p>
                    <p>{{item.chind}}</p>
                </div>
               
                <div class="edit">
                    <img src="../../assets/user/edit2.png"  @click="edit(item)">
                    <img src="../../assets/user/del.png" alt="" @click="del(item.id)">
                </div>
            </div>
        </div>
    </div>
		
		<el-dialog :visible.sync="dia"   title="常用开票信息" width="450px" @close="close">
			<diaUserTicket ref="ticket" @getuserlist="getuserlist"></diaUserTicket>	
		</el-dialog>
	</div>

</template>

<script>
import {addBillingAPI,addressBillinglistAPI,delBillingAPI} from "@/api/user"
import  diaUserTicket from "@/components/com/diaUserTicket"
    export default {
        components:{
            diaUserTicket
        },
  data () {
    return {
      sign: 1,
      list:[],
      dia:false,
	
    }
  },
  created () {
      this.getuserlist()
  },
  filters:{
      oldtype(t){
          if (t==1) {
              return "成人"
          }else{
              return "儿童"
          }
      },
      cardtype(t){
       

          let arr=["未知","身份证","军官证","港澳通行证","护照","回乡证","台胞证"]
          let i=parseInt(t)
          return arr[i]
      }
  },
  methods: {
    
    getuserlist(dia){
		if (!dia) {
			this.dia=dia
		}
        addressBillinglistAPI({id:'all'}).then(res=>{
            this.list=res.user_billing
        })
    },
    del(id){
        delBillingAPI({id}).then(res=>{
            this.getuserlist()
            this.$message("删除成功！")
        })
    }, 
    edit(item){
        
        this.dia=true
        this.$nextTick(()=>{
           this.$refs.ticket.form1={
                id:item.id,
                issue_type:item.issue_type,
                billing_type:item.billing_type,
                billing_rise:item.billing_rise,
                content:item.content,
                chind:item.chind,
            }
        })
        
       
    },
    close(){
		this.$refs.ticket.form1={
		  id:null,
		  issue_type:"个人",
		  billing_type:'增值税普通发票',
		  billing_rise:"",
		  content:"",
		  chind:""
	  }
    }
  }
}
</script>

<style lang="scss"  scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
        min-height: 500px;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			display: flex;
            align-items: center;
            img{
                margin-left: 40px;
                margin-right: 10px;
                width: 15px;
            }
            .add{
                color: #FF7C00;
                font-size: 14px;
                cursor: pointer;
            }
		}

		.rbox{
			display: flex;
			flex-wrap: wrap;
			.box{
				width: 470px;
				border: 1px solid #e6e6e6;
				border-radius: 7px;
				margin: 10px;
				padding:0 10px 10px;
				.b1{
					display: flex;
                     
					margin-top: 10px;
					p:nth-child(1){
						color: #333;
						// margin-right: 10px;
                        width: 100px;
					}
					p{
						span{
							margin-right: 10px;
						}
					}
                    p:nth-child(2){
						color: #666;
                        flex: 1;
						// margin-right: 10px;
					}
                    >div{
                        display: flex;
                        align-items: center;
                    }
                   .edit{
                       img{
                           width: 20px;
                            height:20px;
                           margin: 0 10px;
                       }
                   }
				}
                .b2{
                    align-items: center;
                    justify-content: space-between;
                }

                
			}

		}
        .el-dialog__body{
	padding:0px 20px;
}

	}

</style>
