<template>
    <div class="fp">
        <p>
            <!-- <strong>索要发票</strong> -->
        说明：发票将实行到付，快递费由您自行承担</p>
        <div class="fpform">
            <div>
                <p>开具类型</p>
                <p>
                    <el-radio v-model="form1.issue_type" label="个人"  >个人</el-radio>
                    <el-radio v-model="form1.issue_type" label="企业" >企业</el-radio>
                </p>
            </div>
            <div>
                <p>发票类型</p>
                <p>
                    <el-radio v-model="form1.billing_type" label="增值税普通发票">增值税普通发票</el-radio>
                </p>
            </div>
            <div>
                <p>发票抬头</p>
                <p>
                    <input type="text" v-model="form1.billing_rise" placeholder="请输入发票抬头" style="text-indent: 10px;">
                </p>
            </div>
            <div>
                <p>发票内容</p>
                <p>
                    <el-select v-model="form1.content" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </p>
            </div>
            <div v-if="form1.issue_type=='企业'">
                <p>税务登记证号</p>
                <p>
                    <input type="text" v-model="form1.chind" placeholder="请填写税务登记证号" 
                     :maxlength="18" style="text-indent: 10px;">
                </p>
            </div>
            <div class="butt">
                <p @click="add">确定</p>
            </div>
        </div>
    </div>
</template>

<script>
import {addBillingAPI,userBillingUpdate} from "@/api/user"
export default {

    data () {
    return {
        sign: 1,
        list:[],
        dia:false,
        form1:{
           
            issue_type:"个人",
            billing_type:'增值税普通发票',
            billing_rise:"",
            content:"",
            chind:""
        },
        options: [{
            value: '旅游费',
            label: '旅游费'
        }, {
            value: '服务费',
            label: '服务费'
        },{
            value: '活动费',
            label: '活动费'
        }]

    }
    },
    created () {
        
    },

    methods: {
    
        add(){
            if (this.form1.issue_type=="个人") {
                this.form1.chind=""
            }
            if (this.form1.id) {
                userBillingUpdate(this.form1).then(res=>{
                    this.$emit("getuserlist",false)
                    this.$message("修改成功")
                    
                })
            } else {
                addBillingAPI(this.form1).then(res=>{
                    this.$emit("getuserlist",false)
                    this.$message("添加成功")
                    
                })
            }
        },

    }
}
</script>
    
<style lang="scss" scoped>
   
.fp{
    .el-radio__input.is-checked .el-radio__inner {
        border-color: #ff7c00;
        background: #ff7c00;
    }
    .el-radio__input.is-checked+.el-radio__label {
        color: #ff7c00;
    }
    >p{
        line-height: 45px;
        font-size: 12px;
        color: #999;
        strong{
            font-size: 16px;
            margin-right: 15px;
        }
    }
    .fpform{
        >div{
            display: flex;
            align-items: center;
            line-height: 40px;
            color: #333333;
            p:nth-child(1){
                width: 100px;
            }
            input{
                background: #f6f7f9;
                border-radius: 4px;
                width: 287px;
                height: 35px;
                
            }
        }
    }
    .butt{
        p{
            width: 70px;
            line-height: 35px;
            opacity: 1;
            font-size: 14px;
            background: #ff7c00;
            border-radius: 2px;
            text-align: center;
            color: #ffffff;
            margin:10px auto;
            cursor: pointer;
        }
    }
}
        
    
    </style>
    